html {
  background-color: #282c34;
  color-scheme: dark;
}

#root {
  background-color: #282c34;
}

body {
  margin: 0;
  font-family: "Source Code Pro", "Courier New", Courier, monospace !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #282c34;
}

.clr-orange {
  color: #e5840f;
}

.main {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.header {
  flex: 0 0 auto;
}

.body {
  flex: 1 1 auto;
}

.footer {
  flex: 0 0 auto;
  border-top: 1px solid #4f5153;
}
